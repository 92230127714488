<template>
  <Select
    class="category-type"
    v-model="ageModel"
    @on-change="ageChange"
    placeholder="请选择年龄段"
    :disabled="disabled"
  >
    <Option v-for="item in ageGroup" :value="item.value" :key="item.text">{{ item.text }}</Option>
  </Select>
</template>

<script>
import commonService from "./commonService";
export default {
  data() {
    return { ageModel: "", ageGroup: null };
  },
  props: {
    disabled: {
      default: false
    },
    ageType: {
      required: true
    }
  },
  methods: {
    // 查询年龄分类
    getAgeType() {
      commonService.getAgeType().then(res => {
        this.ageGroup = res;
      });
    },
    ageChange(v) {
      this.$emit("update:ageType", v);
    }
  },
  created() {
    this.getAgeType();
  },
  watch: {
    ageType: {
      handler() {
        this.ageModel = this.ageType;
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.category-type {
  width: 120px;
  
}
</style>